<template>
    <v-container fluid>

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-4">
                                <v-autocomplete prepend-inner-icon="mdi-factory" clearable rounded solo dense v-model="millModel" :items="millIDLists" item-value="origin" item-text="origin" label="origin" class="ma-0 pa-0 border-12" hide-details=true></v-autocomplete>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" rounded solo readonly v-bind="attrs" v-on="on" dense clearable prepend-inner-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" rounded solo readonly v-bind="attrs" v-on="on" dense clearable prepend-inner-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-4 text-right">
                                <v-btn class="mt-3" color="primary" elevation="4" rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>  
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h6 class="cyan-text text-darken-3 m-0">Average Yield By Origin Graph</h6>
                        <div id="chartContainer1" style="height: 500px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h6 class="cyan-text text-darken-3 m-0">Average Yield By Origin Table</h6>
                        <v-data-table :headers="header" :items="dataList" :loading="loadingDatatable" item-class="tr_datatable" hide-default-footer fixed-header height="400" :divider="true" :light="true"></v-data-table>
                    </div>
                </div>
            </div>

        </div>

    </v-container>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Production',
                disabled: false,
                href: '#',
                },
                {
                text: 'Avg. Yield By Origin Analysis',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millIDLists: [],
            modalDateFrom: false,
            dateFrom: '',
            modalDateTo: false,
            dateTo: '',
            header: [],
            headersColumn:{},
            dataList: [],
            showDataDetail:true,
            loadingDatatable: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            $('.cardGraph').hide()
            this.$store.dispatch('setOverlay', true)
            
            await axios.get(`${process.env.VUE_APP_URL}/api/sm/productionSM`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.millIDLists = res.data.mill
                this.$store.dispatch('setOverlay', false)

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        async getChartData(){

            this.$store.dispatch('setOverlay', true)
            this.loadingDatatable = true
            $('.cardGraph').show()
            
            await axios.post(`${process.env.VUE_APP_URL}/api/sm/productionSM/getChartData`, {   
                mill: this.millModel ? this.millModel : '',
                dateFrom: this.dateFrom ? new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"") : '',
                dateTo: this.dateTo ? new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"") : ''
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                let responseData = res.data.result
                const transformedData = this.splitData(responseData)
                const dataChart = this.createChartData(transformedData)
                this.getChart(dataChart, "chartContainer1")
                this.dataList = this.createTabel(responseData)
                this.header = this.createHeaderTable(this.dataList, 'Origin')
                this.loadingDatatable = false

                this.$store.dispatch('setOverlay', false)
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)

            })

        },

        splitData(data) {
            let transformedData = {};

            data.forEach(entry => {
                const { y, label, x } = entry;
                if (!(label in transformedData)) {
                    transformedData[label] = [];
                }
                transformedData[label].push({ label: x, y: parseFloat(y) });
            });

            const allXValues = data.map(entry => entry.x);
            const uniqueXValues = [...new Set(allXValues)];

            for (const label in transformedData) {
                uniqueXValues.forEach(x => {
                    const entry = transformedData[label].find(e => e.label === x);
                    if (!entry) {
                        transformedData[label].push({ label: x, y: 0 });
                    }
                });
                transformedData[label] = transformedData[label].sort((a, b) => a.label.localeCompare(b.label));
            }

            return transformedData;
        },

        createChartData(data){
            var result =[];
            for (let key in data) {
                result.push({
                    type: 'line',
                    // indexLabel: "{y}",
                    // indexLabelFontSize: 10,
                    // indexLabelFontColor: "#FFF",
                    name: key,
                    showInLegend: true,
                    visible: true,
                    // indexLabelPlacement: "outside",
                    // indexLabelOrientation: "horizontal",
                    yValueFormatString: "###,## %",
                    dataPoints: data[key]
                })
            }
            return result;
        },

        getChart(dataPoint, container){

            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "Yield",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    horizontalAlign: "center",
                    verticalAlign: "bottom",
                },
                data: dataPoint
            });
            chart.render();
        },

        createTabel(localData){
            let transformedData = {};
            localData.forEach(entry => {
                if (!transformedData[entry.label]) {
                    transformedData[entry.label] = {};
                }
                transformedData[entry.label][entry.x] = this.$store.getters.convertToCurrencyUs(entry.y) + ' %';
            });

            let result = [];
            for (const label in transformedData) {
                const obj = { "label": label, ...transformedData[label] };
                result.push(obj);
            }
            return result
        },
        createHeaderTable(localData, mainLabel){
            let yValues = [];
            localData.forEach(entry => {
                for (const key in entry) {
                    if (key !== 'label' && !yValues.includes(key)) {
                        yValues.push(key);
                    }
                }
            });
            let header = [
                { text: mainLabel, value: 'label', align: 'start' }
            ];

            yValues.forEach(yValue => {
                header.push({ text: yValue, value: yValue });
            });

            return header
        },

        // async renderChart(data)
        // {

        //     var chart = new CanvasJS.Chart("chartContainer1", {
        //         animationEnabled: true,
        //         theme: "light2",
        //         title:{
        //             text: "Avg. Yield By Origin Analysis Chart"
        //         },
        //         subtitles: [{
        //             text: "Click for details",		
        //             fontColor: "grey",
        //         }],
        //         axisX:{
        //             crosshair: {
        //                 enabled: true,
        //                 snapToDataPoint: true,
        //             },
        //         },
        //         axisY: {
        //             titleFontSize: 24,
        //             crosshair: {
        //                 enabled: true,
        //                 snapToDataPoint: true
        //             },
        //             gridColor: "#005f73"
        //         },
        //         legend: {
        //             fontColor: "red",
        //             cursor: "pointer",
        //             itemclick: this.toggleDataSeries
        //         },
        //         toolTip:{
        //             shared:true
        //         },
        //         data: [
        //         {
        //             type: "column",
        //             indexLabel: "{y}",
        //             axisYType: "secondary",
        //             name: '{label}',
        //             indexLabelFontSize: 12,
        //             showInLegend: true, 
        //             indexLabelOrientation: "horizontal",
        //             yValueFormatString: "###,## %",
        //             indexLabelFontColor: "#a2d2ff",
        //             dataPoints: data,
        //         }]
        //     })

        //     chart.render()

        // },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>